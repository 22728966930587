/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Vollkorn_400Regular = require('./Vollkorn_400Regular.ttf');
export const Vollkorn_400Regular_Italic = require('./Vollkorn_400Regular_Italic.ttf');
export const Vollkorn_600SemiBold = require('./Vollkorn_600SemiBold.ttf');
export const Vollkorn_600SemiBold_Italic = require('./Vollkorn_600SemiBold_Italic.ttf');
export const Vollkorn_700Bold = require('./Vollkorn_700Bold.ttf');
export const Vollkorn_700Bold_Italic = require('./Vollkorn_700Bold_Italic.ttf');
export const Vollkorn_900Black = require('./Vollkorn_900Black.ttf');
export const Vollkorn_900Black_Italic = require('./Vollkorn_900Black_Italic.ttf');
